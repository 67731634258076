<template>
  <div>
    <base-section
      id="Application"
      space="0"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="Online Application Coming Soon."
              class="brown lighten-1"
            />
            <base-body space="0">
              <v-container>
                <v-row justify="center">
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-img
                      src="/images/coming-soon-21.png"
                      max-height="500"
                      max-width="500"
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="4"></v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'

  export default {
    name: 'SectionMap',
    data: () => ({
      search: '',
    }),
    provide: {
      heading: { align: 'center' },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>
